#site-wrapper{
  display: flex;
  > .content-col{
    flex-direction: column;
    padding: 15px;
    @media(min-width: $screen-size-md){
      flex-direction: row;
      padding: 25px;
    }
  }
  @media(min-width: $screen-size-md) {
    > nav.content-col {
      width: 20%;
      flex-grow: 0;
    }
  }
  @media(min-width: $screen-size-md) {
    > main.content-col {
      width: 60%;
      flex-grow: 1;
      padding: 25px 50px;
    }
  }
  @media(min-width: $screen-size-md) {
    > aside.content-col {
      width: 20%;
      flex-grow: 0;
    }
  }
}
.module{
  padding: 60px 0;
}
.content-small{
  max-width: $content-extra-small-width;
  margin: auto;
}
.btn,
input[type="submit"],
button{
  display: inline-block;
  padding: 15px 30px;
  background-color: $brand-primary;
  color: #fff;
  border: 1px solid $brand-primary;
  transition: background-color .3s, color .3s;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  &:hover{
    color: $brand-primary;
    background-color: #fff;
  }
}
.info-icon{
  margin-left: 10px;
  display: inline-block;
  color: $brand-primary;
}