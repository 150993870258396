*{
  /* hyphens */
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
img{
  width: 100%;
  height: auto;
}
a{
  color: $brand-blue;
  text-decoration: none;
  &:hover{
    color: $text-color;
  }
}
ul{
  list-style: none;
  ul{
    list-style: none;
  }
  li{
    margin: 0;
    >ol, >ul{
      margin: 0 0 1rem 2rem;
    }
  }
}