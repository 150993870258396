.provider{
  .provider-section{
    margin-top: 20px;
  }
  &.provider-box{
    display: flex;
    .provider-col{
      flex-grow: 1.75;
      width: 40%;
      padding: 20px;
      &.provider-image{
        position: relative;
        flex-grow: 1;
      }
    }
  }
}