body{
  font-family: 'Raleway', sans-serif;
  font-size: $body-font-size;
  line-height: 21px;
}
h1, h2, h3, h4, h5{
  font-weight: 600;
  margin: 0;
  margin-bottom: 20px;
}
h1{
  font-size: 40px;
  line-height: 42px;
}
h5{
  font-size: 18px;
  line-height: 21px;
}
b{
  font-weight: 600;
}