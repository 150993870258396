input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="week"], textarea, select,
.acf-field input[type="text"], .acf-field input[type="password"], .acf-field input[type="date"], .acf-field input[type="datetime"], .acf-field input[type="datetime-local"], .acf-field input[type="email"], .acf-field input[type="month"], .acf-field input[type="number"], .acf-field input[type="search"], .acf-field input[type="tel"], .acf-field input[type="time"], .acf-field input[type="url"], .acf-field input[type="week"], .acf-field textarea, .acf-field select,{
  display: inline-block;
  max-width: $form-element-width;
  border: 1px solid $anthracite;
}
.acf-field.acf-field-wysiwyg{
  max-width: $form-element-width;
}
.acf-form-submit{
  margin-top: 20px;
  input[type="submit"]{
    border: 1px solid $brand-blue;
    transition: background-color .3s ease-in, color .3s ease-in;
    background-color: $brand-blue;
    color: #fff;
    cursor: pointer;
    &:hover{
      background-color: transparent;
      color: $brand-blue;
    }
  }
}
.form-section{
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 40px;
  &:after{
    content: ' ';
    border-bottom: 1px solid $anthracite;
    width: 450px;
    position: absolute;
    bottom: 0;
  }
  &:last-of-type{
    &:after{
      content: '';
      border: 0;
    }
  }
}
.form-group{
  margin-bottom: 20px;
  label{
    display: block;
    margin-bottom: 10px;
  }
  &.form-group--inline{
    display: inline-block;
  }
  &.form-group--radio{
    display: flex;
    flex-direction: row;
    label,
    input[type="radio"]{
    }
    input[type="radio"]{
      width: 30px;
      margin-right: 10px;
    }
  }
}
input[type="text"],
input[type="email"],
input[type="password"],
textarea{
  padding: 10px;
  border: 1px solid $brand-dark !important;
  max-width: 450px;
  width: 100%;
  font-size: $body-font-size;
  &.parsley-error{
    border: 1px solid $error-color !important;
  }
}
.filepond--root{
  max-width: 450px;
  width: 100%;
}
.toggle-upload-box{
  display: none;
}
.form-upload{
  position: relative;
  overflow: hidden;
  input[type="file"]{
    position: absolute;
    left: -30000px;
  }
  label{
    cursor: pointer;
    display: inline-block;
  }
}