.acf-field-wysiwyg{
  .wp-editor-tabs{
    button {
      background: #f5f5f5;
    }
  }
  .mce-toolbar-grp{
    button{
      background-color: transparent !important;
    }
  }
}
.acf-field-gallery{
  .button{
    background-color: transparent;
  }
}
.acf-fields>.acf-field{
  padding: 0 !important;
  margin-top: 20px !important;
  border-top: 0 !important;
}
.filepond--drop-label.filepond--drop-label label{
  font-size: $body-font-size;
}
.filepond--file-info .filepond--file-info-main{
  font-size: 16px;
  line-height: 18px;
}
.filepond--file-info .filepond--file-info-sub{
  font-size: 14px;
  line-height: 16px;
}