.placement{
  display: flex;
  margin-bottom: 20px;
  &.placement-box{
    border: 1px solid $border-color;
  }
  .placement-section{
    margin-top: 20px;
  }
  .placement--col{
    flex: 1;
    width: 40%;
    padding: 20px;
  }
  .placement--col.placement--image{
    position: relative;
    width: 20%;
    padding: 0;
    overflow: hidden;
    img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .placement--details{
    .placement-detail{
      margin-bottom: 5px;
    }
  }
  .placement--description{
    .placement--description--text {
      margin-bottom: 20px;
    }
    .placement--description--cta{

    }
  }
}